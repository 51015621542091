import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Spacer from "../components/spacer"
import { graphql } from "gatsby"

const ContactPage = ({ data }) => {
	return (
		<Layout className='with-background'>
			<SEO title="Disclaimer" />
			<Spacer />
				<div className='text-center'>
					<p className='m0 mb40 h2'>Disclaimer</p>
					<div className='m0 max-450 ma lh2'>
						{data.allDatoCmsDisclaimerPage.nodes[0].disclaimer}
					</div>
				</div>
			<Spacer className='mh-30'/>
			<div className='pl40 pr40 m-pl20 m-pr20'>
				<div className='bb1' />
			</div>
		</Layout>
	)

}

export default ContactPage

export const query = graphql`
query DisclaimerQuery {
  allDatoCmsDisclaimerPage {
    nodes {
      disclaimer
    }
  }
}
`